<template>
    <div v-click-outside-textInput="closePopup" class="relative">
        <div class="">
            <p class="label_css flex justify-between" v-if="isShowBelow === undefined">
            <span>{{lableText}}</span>
            <span class="text contents" v-if="showcharLimit">{{inputValue.length}}/{{textMaxlength}}</span>
          </p>
            <textarea 
            :maxlength="textMaxlength"
            @keypress="keyPressAction"
            @keypress.enter="enterkeyPressAction"  
            @blur="blurAction"
            @input="inputChangeAction(inputValue)"
            @change="change"
            @keyup="keyup"
            :pattern="pattern"
            @click="onClickInputAction"
            @focus="focus"
            v-model="inputValue"
            :disabled="disabled"
            :placeholder="placholderText"
            :readonly="setReadOnly"
            :type="inputTypeText"
            :rows="rows" :cols="cols"
            :autofocus="setAutoFocust"
            autocomplete="off"
            :id="inputId"
            class="cust_text_field py-4  px-2 w-full  m-px flex text-text1 bg-white rounded items-center"
            :class="`${inputType === 'color' ? 'h-12 py-0' : 'py-3'} ${textRight ? 'text-right pr-2' : ''} ${showError ? 'ring-1 ring-error' : 'border border-gray2 focus:border-primary focus:ring-1 border flex text-text1 focus:border-primary focus:ring-1'}
            pr-${inputType === 'password' || showDate ? '8' : '0'}
            `"></textarea>
            <p class="label_css -mt-1 flex justify-between" v-if="isShowBelow !== undefined">
              <span>{{lableText}}</span>
              <span class="text contents" v-if="showcharLimit">{{inputValue.length}}/{{textMaxlength}}</span>
            </p>
            </div>
          

        </div>
</template>
<script>
import Vue from 'vue'
Vue.directive('click-outside-textInput', {
  bind: function (el, binding, vnode) {
    el.clickOutsideEvent = function (event) {
      // here I check that click was outside the el and his children
      if (!(el === event.target || el.contains(event.target))) {
        // and if it did, call method provided in attribute value
        vnode.context[binding.expression](event)
      }
    }
    document.body.addEventListener('click', el.clickOutsideEvent)
  },
  unbind: function (el) {
    document.body.removeEventListener('click', el.clickOutsideEvent)
  }
})
export default {
    props: [ 'cols', 'showcharLimit', 'rows', 'pattern', "textRight", "isShowBelow", "inputext", "textMaxlength", 'lableText', 'inputId', 'autoFocus', 'fieldError', 'setReadOnly', 'placholderText', 'inputType', "showDate", "disabled", "showTime"],
  data() {
    return {
      inputTypeText: 'text',
      hidePassword: false,
      showError: false,
      setAutoFocust: false,
      disabledBtn: false,
      inputValue: ''
    };
  },
  watch: {
    fieldError: {
        handler () {
          console.log('fieldError', this.fieldError);
          this.showError = this.fieldError
        }
    },
    hidePassword: {
        handler () {
          if (this.hidePassword) {
            this.inputTypeText = 'password'
            document.getElementById(`${this.inputId}`).focus()
          } else {
            this.inputTypeText = 'text'
            document.getElementById(`${this.inputId}`).focus()
          }
        }
    },
    inputext: {
        handler () {
            if (this.inputext) {
                this.inputValue = this.inputext
            } else {
              this.inputValue = this.inputext
            }
        }
    }
  },
  mounted() {
    this.showError = this.fieldError
    this.inputTypeText = this.inputType
    if (this.disabled) {
      this.disabledBtn = this.disabled
    }
    setTimeout(() => {
      this.inputValue = this.inputext
     }, 200);
    setTimeout(() => {
      if (this.autoFocus) {
        document.getElementById(`${this.inputId}`).focus()
      }
    }, 10);
  },
  methods: {
    inputChangeAction (text) {
        this.$emit('inputChangeAction', text)
    },
    change () {
        this.$emit('change')
    },
    keyPressAction () {
        this.$emit('keyPressAction')
    },
    enterkeyPressAction () {
        this.$emit('enterkeyPressAction')
    },
    blurAction () {
        this.$emit('blurAction')
    },
    focus () {
        this.$emit('focus')
    },
    onClickInputAction () {
      document.getElementById(`${this.inputId}`).focus()
        this.$emit('onClickInputAction')
    },
    closePopup () {
        this.$emit('closePopup')
    },
    keyup () {
        this.$emit('keyup')
    }
  },
};
</script>
<style scoped>
</style>
